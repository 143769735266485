import { useRef, useState, useCallback } from "react";
import { Checkbox, ChoiceGroup, IChoiceGroupOption, Panel, DefaultButton, Spinner, TextField, SpinButton, Stack } from "@fluentui/react";
import { useDropzone } from "react-dropzone";

import styles from "./Custom.module.css";

import { askCustomApi, indexDataApi, Approaches, AskResponse, AskRequest } from "../../api";
import { Answer, AnswerError } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton/SettingsButton";
import { Dropdown, IDropdownOption } from "@fluentui/react";

const Custom = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [approach, setApproach] = useState<Approaches>(Approaches.RetrieveThenRead);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [promptTemplatePrefix, setPromptTemplatePrefix] = useState<string>("");
    const [promptTemplateSuffix, setPromptTemplateSuffix] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [category, setCategory] = useState<string>("");

    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length !== 1) {
            setUploadError("Please upload a single pdf file");
            return;
        }
        const uploadedFile = acceptedFiles[0];
        if (uploadedFile.type !== "application/pdf") {
            setUploadError("Please upload a single pdf file");
            return;
        }
        setUploadError("");
        setSelectedFile(uploadedFile);
        // Do something with the accepted file
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { "application/pdf": [".pdf"] },
        maxFiles: 1
    });

    const dropdownOptions: IDropdownOption[] = [
        { key: "healthcare", text: "Healthcare" },
        { key: "manufacturing", text: "Manufacturing" },
        { key: "technology", text: "Technology" },
        { key: "irs_np", text: "IRS for Non-profits" },
        { key: "ford", text: "Legal Assistant" },
        { key: "copilot", text: "Owner's Co-pilot" }
    ];

    const defaultSelectedKey = "manufacturing";
    const dropdownStyles = {
        dropdown: {
            width: 340,
            height: 40,
            fontSize: 12,
            backgroundColor: "white",
            outline: "none",
            border: "1px solid #4c4c4e",
            borderRadius: 4,
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
            marginBottom: 20
        }, // Change width of dropdown container
        dropdownItem: { height: 40 },
        dropdownSelectedItem: { height: 40 },
        dropdownItemSelected: { height: 40 },
        dropdownChevron: { height: 40 },
        title: { fontSize: 15, height: "100%", paddingTop: 1 } // Change font size of selected option
    };

    const [selectedOption, setSelectedOption] = useState<IDropdownOption | undefined>(dropdownOptions.find(option => option.key === defaultSelectedKey));

    const lastQuestionRef = useRef<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [uploadError, setUploadError] = useState<string>("");
    const [answer, setAnswer] = useState<AskResponse>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const cancelIndex = () => {
        setCategory("");
        setSelectedFile(null);
        setAnswer(undefined);
    };

    const indexDataRequest = async () => {
        setIsLoading(true);
        setCategory("");
        setUploadError("");

        try {
            if (selectedFile) {
                const result = await indexDataApi(selectedFile);
                console.log(result);
                setCategory(result.category_name);
            }
        } catch (e) {
            setUploadError("Failed to index the document");
        } finally {
            setIsLoading(false);
        }
    };

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const request: AskRequest = {
                question,
                approach,
                category: selectedOption?.key.toString() || "",
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    promptTemplatePrefix: promptTemplatePrefix.length === 0 ? undefined : promptTemplatePrefix,
                    promptTemplateSuffix: promptTemplateSuffix.length === 0 ? undefined : promptTemplateSuffix,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions
                }
            };
            const result = await askCustomApi(request);
            setAnswer(result);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onPromptTemplatePrefixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplatePrefix(newValue || "");
    };

    const onPromptTemplateSuffixChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplateSuffix(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onApproachChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        setApproach((option?.key as Approaches) || Approaches.RetrieveThenRead);
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };
    const onDropdownChange = (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        setSelectedOption(option);
    };
    const onShowCitation = (citation: string) => {
        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }
    };

    const onToggleTab = (tab: AnalysisPanelTabs) => {
        if (activeAnalysisPanelTab === tab) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }
    };
    const onSaveContent = (content: AskResponse) => {
        //console.log(content);
    };

    const approaches: IChoiceGroupOption[] = [
        {
            key: Approaches.RetrieveThenRead,
            text: "Retrieve-Then-Read"
        },
        {
            key: Approaches.ReadRetrieveRead,
            text: "Read-Retrieve-Read"
        },
        {
            key: Approaches.ReadDecomposeAsk,
            text: "Read-Decompose-Ask"
        }
    ];

    return (
        <div className={styles.oneshotContainer}>
            <div className={styles.oneshotTopSection}>
                <SettingsButton className={styles.settingsButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                <br />
                {/**<img src={datasciencedojo_small} alt="Data Science Dojo Logo" className={styles.dataScienceDojoLogo} />*/}
                <h1 className={styles.oneshotTitle}>Try your data</h1>
                <h2 className={styles.chatEmptyStateSubtitle}>Choose industry for your data.</h2>
                <Dropdown options={dropdownOptions} styles={dropdownStyles} selectedKey={selectedOption?.key} onChange={onDropdownChange} />
                <h2 className={styles.chatEmptyStateSubtitle}>Select the PDF file you wish to ask questions from by uploading or dragging and dropping it.</h2>

                <div className={styles.oneshotQuestionInput}>
                    <Stack horizontal className={styles.questionInputContainer}>
                        <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <p>Drag and drop some file here, or click to select files</p>
                        </div>
                    </Stack>
                </div>
                {selectedFile ? (
                    <div className={styles.oneshotQuestionInput}>
                        <Stack horizontal className={styles.pdfContainer}>
                            <div style={{ width: "15%", justifyContent: "start", color: "black" }}>
                                <p>Uploaded File :</p>
                            </div>
                            <div style={{ width: "40%", justifyContent: "center" }}>
                                <p>{selectedFile.name} </p>
                            </div>
                            <div style={{ width: "10%", justifyContent: "end" }}>
                                <DefaultButton className={styles.cancelButton} onClick={() => cancelIndex()}>
                                    Cancel
                                </DefaultButton>
                            </div>
                            <div style={{ width: "10%", justifyContent: "end" }}>
                                <DefaultButton className={styles.indexButton} onClick={() => indexDataRequest()}>
                                    Analyze
                                </DefaultButton>
                            </div>
                        </Stack>
                    </div>
                ) : (
                    <></>
                )}

                {uploadError ? (
                    <div className={styles.oneshotQuestionInput}>
                        <Stack horizontal className={styles.errorContainer}>
                            <div style={{ width: "10%", justifyContent: "center" }}></div>
                            <div style={{ width: "60%", justifyContent: "center", color: "red" }}>
                                <p>{uploadError}</p>
                            </div>
                            <div style={{ width: "20%", justifyContent: "end" }}>
                                <DefaultButton className={styles.cancelButton} onClick={() => setUploadError("")}>
                                    Close
                                </DefaultButton>
                            </div>
                        </Stack>
                    </div>
                ) : (
                    <></>
                )}

                <div className={styles.oneshotQuestionInput}>
                    <QuestionInput
                        placeholder="Example: Does my plan cover annual eye exams?"
                        disabled={category == ""}
                        onSend={question => makeApiRequest(question)}
                    />
                </div>
            </div>
            <div className={styles.oneshotBottomSection}>
                {isLoading && <Spinner label="Processing file" />}
                {/*!lastQuestionRef.current && <ExampleList onExampleClicked={onExampleClicked} />*/}
                {!isLoading && answer && !error && (
                    <div className={styles.oneshotAnswerContainer}>
                        <Answer
                            answer={answer}
                            onCitationClicked={x => onShowCitation(x)}
                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab)}
                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab)}
                            onSaveContentClicked={() => onSaveContent(answer)}
                        />
                    </div>
                )}
                {error ? (
                    <div className={styles.oneshotAnswerContainer}>
                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                    </div>
                ) : null}
                {activeAnalysisPanelTab && answer && (
                    <AnalysisPanel
                        className={styles.oneshotAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x)}
                        citationHeight="600px"
                        answer={answer}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}
            </div>

            <Panel
                headerText="Configure answer generation"
                isOpen={isConfigPanelOpen}
                isBlocking={false}
                onDismiss={() => setIsConfigPanelOpen(false)}
                closeButtonAriaLabel="Close"
                onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                isFooterAtBottom={true}
            >
                <ChoiceGroup
                    className={styles.oneshotSettingsSeparator}
                    label="Approach"
                    options={approaches}
                    defaultSelectedKey={approach}
                    onChange={onApproachChange}
                />

                {(approach === Approaches.RetrieveThenRead || approach === Approaches.ReadDecomposeAsk) && (
                    <TextField
                        className={styles.oneshotSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />
                )}

                {approach === Approaches.ReadRetrieveRead && (
                    <>
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplatePrefix}
                            label="Override prompt prefix template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplatePrefixChange}
                        />
                        <TextField
                            className={styles.oneshotSettingsSeparator}
                            defaultValue={promptTemplateSuffix}
                            label="Override prompt suffix template"
                            multiline
                            autoAdjustHeight
                            onChange={onPromptTemplateSuffixChange}
                        />
                    </>
                )}

                <SpinButton
                    className={styles.oneshotSettingsSeparator}
                    label="Retrieve this many documents from search:"
                    min={1}
                    max={10}
                    defaultValue={retrieveCount.toString()}
                    onChange={onRetrieveCountChange}
                />
                <TextField className={styles.oneshotSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                <Checkbox
                    className={styles.oneshotSettingsSeparator}
                    checked={useSemanticRanker}
                    label="Use semantic ranker for retrieval"
                    onChange={onUseSemanticRankerChange}
                />
                <Checkbox
                    className={styles.oneshotSettingsSeparator}
                    checked={useSemanticCaptions}
                    label="Use query-contextual summaries instead of whole documents"
                    onChange={onUseSemanticCaptionsChange}
                    disabled={!useSemanticRanker}
                />
                {/**<Dropdown label="Industry" options={dropdownOptions} selectedKey={selectedOption?.key} onChange={onDropdownChange} >**/}
            </Panel>
        </div>
    );
};

export default Custom;
