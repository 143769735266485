import React, { useState, useEffect } from "react";
import datasciencedojo_small from "../../assets/smallDataScienceDojo.png";
import styles from "./ImageSearch.module.css"; // Import the CSS styles

type Item = {
    url: string;
    product_name: string;
    text: string;
};

const ImageSearch: React.FC = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [searchResults, setSearchResults] = useState<Item[]>([]);
    const [loading, setLoading] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const handleSearch = async () => {
        try {
            setLoading(true); // Show loader while fetching results

            const response = await fetch("https://func-vector-search.azurewebsites.net/api/vectorized_image_search", {
                method: "POST",
                body: JSON.stringify({ query: searchQuery }),
                headers: {
                    "Content-Type": "application/json"
                }
            });

            if (!response.ok) {
                throw new Error("Failed to fetch search results");
            } else {
                setIsInitialLoad(false); // Update the initial load state
            }

            const data = await response.json();
            setSearchResults(data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false); // Hide loader after fetching results
            setIsInitialLoad(false); // Update the initial load state
        }
    };

    useEffect(() => {
        setIsInitialLoad(true); // Set initial load state to true on component mount
    }, []);

    return (
        <div>
            <div className={styles.imageEmptyState}>
                <img src={datasciencedojo_small} alt="Data Science Dojo Logo" className={styles.dataScienceDojoLogo} />
                <h1 className={styles.imageStateTitle}>Vectorized Image Search</h1>
                <div className={styles.subtitleDiv}>
                    <div className={styles.sub1}></div>
                </div>
            </div>
            <div className={styles.searchBar}>
                <input
                    type="text"
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    className={styles.searchInput}
                    placeholder="Type URL of image or Text; (example: tshirts), to find similar products"
                />
                <button onClick={handleSearch} className={styles.searchButton}>
                    Search
                </button>
            </div>

            {isInitialLoad && <p className={styles.initialLoadText}>Explore Images with Ease: Empowering Image Text Search</p>}

            {loading ? (
                <div className={styles.loaderContainer}>
                    <div className={styles.loader} />
                </div>
            ) : (
                <div className={styles.container}>
                    {searchResults.map((item, index) => (
                        <div key={index}>
                            <img src={item.url} alt="Item" className={styles.image} />
                            <p className={styles.productName}>{item.product_name}</p>
                            <p className={styles.text}>{item.text}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ImageSearch;
