import { Example } from "./Example";

import styles from "./Example.module.css";

export type ExampleModel = {
    text: string;
    value: string;
};

//All Industries
const EXAMPLES_ALL: ExampleModel[] = [
    {
        text: "What is the Whistleblower Policy of Contoso?",
        value: "What is the Whistleblower Policy of Contoso?"
    },
    {
        text: "What is NHC's policy on the protection and proper use of its assets?",
        value: "What is NHC's policy on the protection and proper use of its assets?"
    },
    { text: "What is the vision of Data Science Dojo?", value: "What is the vision of Data Science Dojo?" }
];

//new code for example prompts
const EXAMPLES_HEALTH: ExampleModel[] = [
    {
        text: "What are the Standards of Conduct that NHC partners are required to follow?",
        value: "What are the Standards of Conduct that NHC partners are required to follow?"
    },
    {
        text: "What is NHC's policy on the protection and proper use of its assets?",
        value: "What is NHC's policy on the protection and proper use of its assets?"
    },
    {
        text: "How does NHC ensure that the personal privacy and confidentiality of patient and resident records are protected?",
        value: "How does NHC ensure that the personal privacy and confidentiality of patient and resident records are protected?"
    }
];

const EXAMPLES_CANCER: ExampleModel[] = [
    {
        text: "Is the development of cancer cells influenced by genetic factors?",
        value: "Is the development of cancer cells influenced by genetic factors?"
    },
    {
        text: "What is meant by the HER2 status of the cancer?",
        value: "What is meant by the HER2 status of the cancer?"
    },
    {
        text: "How can I lower my chances of getting breast cancer?",
        value: "How can I lower my chances of getting breast cancer?"
    }
];

const EXAMPLES_MANUFAC: ExampleModel[] = [
    {
        text: "What is the Whistleblower Policy of Contoso?",
        value: "What is the Whistleblower Policy of Contoso?"
    },
    {
        text: "What are data security policies at Contoso Electronics?",
        value: "What are data security policies at Contoso Electronics?"
    },
    {
        text: "How is Workplace Safety ensured at Contoso Electronics?",
        value: "How is Workplace Safety ensured at Contoso Electronics?"
    }
];

const EXAMPLES_TECH: ExampleModel[] = [
    {
        text: "What is the management structure at Data Science Dojo?",
        value: "What is the management structure at Data Science Dojo??"
    },
    {
        text: "What is the vision of Data Science Dojo?",
        value: "What is the vision of Data Science Dojo?"
    },
    {
        text: "What are the expected working hours for client-facing teams at Data Science Dojo?",
        value: "What are the expected working hours for client-facing teams at Data Science Dojo?"
    }
];
const EXAMPLES_IRS: ExampleModel[] = [
    {
        text: "How do I request an extension for filing my tax return?",
        value: "How do I request an extension for filing my tax return?"
    },
    {
        text: "What information does Part II of the Schedule K-1 (Form 1120-S) provide about the shareholder's details?",
        value: "What information does Part II of the Schedule K-1 (Form 1120-S) provide about the shareholder's details?"
    },
    {
        text: "Can I deduct expenses for my home office if I am self-employed?",
        value: "Can I deduct expenses for my home office if I am self-employed?"
    }
];

const EXAMPLES_IRS_NP: ExampleModel[] = [
    {
        text: "What is the process to appeal a denial for tax-exempt status?",
        value: "What is the process to appeal a denial for tax-exempt status?"
    },
    {
        text: "How are the salaries of employees in a tax-exempt organization treated for tax purposes?",
        value: "How are the salaries of employees in a tax-exempt organization treated for tax purposes?"
    },
    {
        text: "Are tax-exempt organizations liable to pay excise duties?",
        value: "Are tax-exempt organizations liable to pay excise duties?"
    }
];

const EXAMPLES_NUTRITION: ExampleModel[] = [
    {
        text: "Provide list of protien rich food to add to my diet.",
        value: "Provide list of protien rich food to add to my diet."
    },
    {
        text: "What types of physical activity are recommended for people with diabetes?",
        value: "What types of physical activity are recommended for people with diabetes?"
    },
    {
        text: "Suggest how sleep quality can be improved.",
        value: "Suggest how sleep quality can be improved."
    }
];

const EXAMPLES_K12: ExampleModel[] = [
    {
        text: "What is the Every Student Succeeds Act, and how does it impact the US education system?",
        value: "What is the Every Student Succeeds Act, and how does it impact the US education system?"
    },
    {
        text: "How can I approach literary analysis effectively and understand the underlying themes in classic literature?",
        value: "How can I approach literary analysis effectively and understand the underlying themes in classic literature?"
    },
    {
        text: "How do schools approach academic needs of students with disabilities?",
        value: "How do schools approach academic needs of students with disabilities?"
    }
];

const EXAMPLES_FORD: ExampleModel[] = [
    {
        text: "Provide a clause for agreements with other companies in the automotive industry?",
        value: "Provide a clause for agreements with other companies in the automotive industry?"
    },
    {
        text: "Generate a template for a marketing agreement between Ford Motors and a promotional agency.",
        value: "Generate a template for a marketing agreement between Ford Motors and a promotional agency."
    },
    {
        text: "In the document for a non-disclosure agreement, what provisions should Ford Motors consider to protect confidential information?",
        value: "In the document for a non-disclosure agreement, what provisions should Ford Motors consider to protect confidential information?"
    }
];

const EXAMPLES_COPILOT: ExampleModel[] = [
    {
        text: "What are the available engine options for the Ford F-150, and how do they differ in terms of performance and fuel efficiency?",
        value: "What are the available engine options for the Ford F-150, and how do they differ in terms of performance and fuel efficiency?"
    },
    {
        text: "When does unlatching your seatbelt activate the automatic shift into park (P) feature in the Ford GT?",
        value: "When does unlatching your seatbelt activate the automatic shift into park (P) feature in the Ford GT?"
    },
    {
        text: "What is the recommended proximity for the remote control of Ford F-150 to operate the front door handles and rear of the vehicle?",
        value: "What is the recommended proximity for the remote control of Ford F-150 to operate the front door handles and rear of the vehicle?"
    }
];
const EXAMPLES_GRANT: ExampleModel[] = [
    // {
    //     text: "What are the key criteria for the non profits that request grant proposals, and how can we meet those requirements in our proposal?",
    //     value: "What are the key criteria for the non profits that request grant proposals, and how can we meet those requirements in our proposal?"
    // },
    // {
    //     text: "What evidence and data should we include in our proposal to demonstrate the potential impact and effectiveness of our project?",
    //     value: "What evidence and data should we include in our proposal to demonstrate the potential impact and effectiveness of our project?"
    // },
    // {
    //     text: "How can we align our project with the Bill & Melinda Gates Foundation's focus areas to enhance our chances of funding?",
    //     value: "How can we align our project with the Bill & Melinda Gates Foundation's focus areas to enhance our chances of funding?"
    // },
    {
        text: "Help me draft cost analysis and budget for a proposal  grant funding of a project",
        value: "Help me draft cost analysis and budget for a proposal  grant funding of a project"
    },
    {
        text: "Help me draft a suitable timeline for a proposal  grant funding of a project",
        value: "Help me draft a suitable timeline for a proposal  grant funding of a project"
    },
    {
        text: "Help me draft a proposal for grant funding",
        value: "Help me draft a proposal for grant funding"
    }
];
const EXAMPLES_MEDICARE: ExampleModel[] = [
    {
        text: "What are the out-of-pocket costs I'll need to pay with Medicare?",
        value: "What are the out-of-pocket costs I'll need to pay with Medicare?"
    },
    {
        text: "How do I find healthcare providers that accept Medicare?",
        value: "How do I find healthcare providers that accept Medicare?"
    },
    {
        text: "What's the difference between Medicare and Medicaid? Can I have both?",
        value: "What's the difference between Medicare and Medicaid? Can I have both?"
    }

    ];

const EXAMPLES_NSA: ExampleModel[] = [
        {
            text: "What are the main objectives of the JRTF?",
            value: "What are the main objectives of the JRTF?"
        },
        {
            text: "Who determines the Levels of Assurance (LoA) in DoD?",
            value: "Who determines the Levels of Assurance (LoA) in DoD?"
        },
        {
            text: "What is UEFI?",
            value: "What is UEFI"
        }
    ];


const EXAMPLES_DECISION: { [key: string]: ExampleModel[] } = {
    healthcare: EXAMPLES_HEALTH,
    manufacturing: EXAMPLES_MANUFAC,
    technology: EXAMPLES_TECH,
    IRS: EXAMPLES_IRS,
    nutrition: EXAMPLES_NUTRITION,
    k12: EXAMPLES_K12,
    "all industries": EXAMPLES_ALL,
    grant: EXAMPLES_GRANT,
    irs_np: EXAMPLES_IRS_NP,
    ford: EXAMPLES_FORD,
    copilot: EXAMPLES_COPILOT,
    medicare: EXAMPLES_MEDICARE,
    cancer: EXAMPLES_CANCER,
    NSA:EXAMPLES_NSA
};

interface Props {
    onExampleClicked: (value: string) => void;
    industry: string;
}
export const ExampleList = ({ onExampleClicked, industry }: Props) => {
    //console.log(industry);  //receives industry string
    const EXAMPLES_TEMP = EXAMPLES_DECISION[industry]; // Use appropriate array based on industry prop
    return (
        <ul className={styles.examplesNavList}>
            {EXAMPLES_TEMP.map((x, i) => (
                <li key={i}>
                    <Example text={x.text} value={x.value} onClick={onExampleClicked} />
                </li>
            ))}
        </ul>
    );
};
