import { useMemo } from "react";
import { Stack, IconButton } from "@fluentui/react";
import DOMPurify from "dompurify";

import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath, getIRSCitationFilePath, getNutritionFilePath, getCancerCitationFilePath, getK12CitationFilePath, getNSACitationFilePath } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onSaveContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    custom?: boolean;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onSaveContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions
}: Props) => {
    let parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);

    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Save" }}
                            title="Save Response"
                            ariaLabel="Save Response"
                            onClick={() => onSaveContentClicked()}
                        />
                    </div>
                </Stack>
            </Stack.Item>

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>

                    {parsedAnswer.citations.some(x => (x.includes("-") && !x.includes("https"))|| x.includes("nsa_")||x.includes("cancer_")|| x.includes("EPUB_") ||x.includes("k12")|| x.includes("nutrition")||x.includes("https")) && (
                            <span className={styles.citationLearnMore}>Citations:</span>
                    )}

                        {parsedAnswer.citations.map((x, i) => {


                            if (x.includes("nsa_") || x.includes("nsa _")) {
                                const path =
                                x.includes("nsa_") || x.includes("nsa _") ? getNSACitationFilePath(x.replace("nsa _", "nsa_")) : getCitationFilePath(x);
                                 return (
                                     <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                         {`${++i}. ${x}`}
                                    </a>
                                  );
                            }
                            
                            else if ((x.includes("-") && !x.includes("https")) || x.includes("EPUB_") || x.includes("EPUB _")) {
                                const path =
                                x.includes("EPUB_") || x.includes("EPUB _") ? getIRSCitationFilePath(x.replace("EPUB _", "EPUB_")) : getCitationFilePath(x);
                           
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            }
                            else if (x.includes("nutrition")) {
                                const path = getNutritionFilePath(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            }

                            else if ( x.includes("cancer_") || x.includes("cancer _")) {
                                const path =
                                x.includes("cancer_") || x.includes("cancer _") ? getCancerCitationFilePath(x.replace("cancer _", "cancer_")) : getCitationFilePath(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            }
                            else if (x.includes("k12")) {
                                const path = getK12CitationFilePath(x);
                                return (
                                    <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                        {`${++i}. ${x}`}
                                    </a>
                                );
                            }
                           
                        })}

                    </Stack>                          
              </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up prompts:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
        </Stack>
    );
};
