import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import Custom from "./pages/custom/Custom";
import Index from "./pages/index/Index";
import IRSChat from "./pages/irschat/IRSChat";
import ImageSearch from "./pages/ImageSearch/ImageSearch";
// import ImageSearch_2 from "./pages/ImageSearch/ImageSearch_2";

initializeIcons();

export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<Chat />} />
                    <Route path="/:option" element={<Chat />} />
                    <Route path="qa" element={<OneShot />} />
                    {/* <Route path="irschat" element={<IRSChat />} /> */}
                    <Route path="custom" element={<Custom />} />
                    <Route path="index" element={<Index />} />
                    <Route path="imagesearch" element={<ImageSearch />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </Router>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
