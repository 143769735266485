import { useRef, useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Checkbox, Panel, DefaultButton, TextField, SpinButton } from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import { Dropdown, IDropdownOption } from "@fluentui/react";

import styles from "./Chat.module.css";
import datasciencedojo_small from "../../assets/smallDataScienceDojo.png";
import { chatApi, Approaches, AskResponse, ChatRequest, ChatTurn, IRSApi, CancerApi, GrantApi, NutritionApi,K12Api,NSAApi } from "../../api";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import { ExampleList } from "../../components/Example";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { SettingsButton } from "../../components/SettingsButton";
import { ClearChatButton } from "../../components/ClearChatButton";
import { parseAnswerToHtml } from "../../components/Answer/AnswerParser";
import DOMPurify from "dompurify";
// import { saveAs } from "file-saver";

const Chat = () => {
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(false);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);

    const { option } = useParams();

    const navigate = useNavigate();

    const dropdownOptions: IDropdownOption[] = [
        //{ key: "all industries", text: "All Industries" },
        //{ key: "healthcare", text: "Healthcare" },
        //{ key: "manufacturing", text: "Manufacturing" },
        //{ key: "technology", text: "Technology" },
        //{ key: "irs_np", text: "IRS For Non-Profits" },
        { key: "IRS", text: "IRS Chatbot" },
        { key: "grant", text: "Grant Writing Companion" },
        { key: "ford", text: "Legal Assistant" },
        { key: "copilot", text: "Car Owner's Co-pilot" },
        { key: "medicare", text: "Medicare Chatbot" },
        { key: "nutrition", text: "Nutrition and fitness Chatbot" },
        { key: "cancer", text: "Cancer Chatbot" },
        { key: "NSA", text: "CyberWise Assistant" },
        { key: "k12", text: "K-12 Education Guide" }
    ];
    const placeholderExample: { [key: string]: string } = {
        //healthcare: "Example: What are the Standards of Conduct that NHC partners are required to follow?",
        //manufacturing: "Example: What are data security policies at Contoso Electronics?",
        //technology: "Example: What is the vision of Data Science Dojo?",
        //"all industries": "Example: What are the Standards of Conduct that NHC partners are required to follow?",
        //irs_np: "Example: What kind of activities does the IRS view as purely for exempt purposes?",
        IRS: "When do I need to file my taxes by?",
        grant: "Get started with your grant proposal here",
        ford: "Example: In what ways does Ford Motor Company collect personal information?",
        copilot: "Example: In what ways does Ford Motor Company collect personal information?",
        nutrition: "How can I improve my sleep quality?",
        cancer: "What is cancer?",
        NSA: "What are the latest cybersecurity advisories and guidance from the National Security Agency (NSA)?",
        k12: "What are the required immunizations for kindergarten admission?"
    };
    const dropdownStyles = {
        dropdown: {
            width: 340,
            height: 40,
            fontSize: 12,
            backgroundColor: "white",
            outline: "none",
            border: "1px solid #4c4c4e",
            borderRadius: 4,
            boxShadow: "0 4px 8px rgba(0,0,0,0.2)"
        }, // Change width of dropdown container
        dropdownItem: { height: 40 },
        dropdownSelectedItem: { height: 40 },
        dropdownItemSelected: { height: 40 },
        dropdownChevron: { height: 40 },
        title: { fontSize: 15, height: "100%", paddingTop: 1 } // Change font size of selected option
    };

    const defaultSelectedKey = "IRS";

    const [selectedOption, setSelectedOption] = useState<IDropdownOption | undefined>(
        dropdownOptions.find(option => option.key === option.toString()) || dropdownOptions.find(option => option.key === defaultSelectedKey)
    );
    useEffect(() => {
        const selected = dropdownOptions.find(opt => opt.key === option);
        if (selected) {
            setSelectedOption(selected);
        }
    }, [option]);

    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();

    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);

    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse][]>([]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        error && setError(undefined);
        setIsLoading(true);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);

        try {
            const history: ChatTurn[] = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            //Selecting Approach for the chat
            let approach = Approaches.ReadRetrieveRead;
            if (selectedOption?.key.toString() === "IRS" || selectedOption?.key.toString() === "medicare") {
                approach = Approaches.IRSChatApproach;
            } else if (selectedOption?.key.toString() === "grant") {
                approach = Approaches.GrantChatApproach;
            } else if (selectedOption?.key.toString() === "nutrition") {
                approach = Approaches.NutritionChatApproach;
            } else if (selectedOption?.key.toString() === "cancer") {
                approach = Approaches.CancerChatApproach;
            } else if (selectedOption?.key.toString() === "k12") {
                approach = Approaches.K12ChatApproach;
            }
            else if (selectedOption?.key.toString() === "NSA") {
                approach = Approaches.NSAChatApproach;
            }
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: approach,
                category: selectedOption?.key.toString() || "",
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };

            let result;
            //Selecting API endpoint to connect with backend
            if (request.category === "IRS" || request.category === "medicare") {
                result = await IRSApi(request);
            } else if (request.category === "grant") {
                result = await GrantApi(request);
            } else if (request.category === "cancer") {
                result = await CancerApi(request);
            } else if (request.category == "nutrition") {
                result = await NutritionApi(request);

            } else if (request.category == "NSA") {
                    result = await NSAApi(request);
            
            } else if (request.category == "k12") {
                result = await K12Api(request);
            } else {
                result = await chatApi(request);
            }
            //const result = await chatApi(request);
            setAnswers([...answers, [question, result]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsLoading(false);
        }
    };

    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
    };

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isLoading]);

    const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setPromptTemplate(newValue || "");
    };

    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "3"));
    };

    const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticRanker(!!checked);
    };

    const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSemanticCaptions(!!checked);
    };

    const onExcludeCategoryChanged = (_ev?: React.FormEvent, newValue?: string) => {
        setExcludeCategory(newValue || "");
    };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onDropdownChange = (_ev?: React.FormEvent<HTMLDivElement>, option?: IDropdownOption): void => {
        setSelectedOption(option);
        if (option) {
            navigate(`/${option.key}`);
        }
    };

    const onExampleClicked = (example: string) => {
        makeApiRequest(example);
    };

    const onShowCitation = (citation: string, index: number) => {
        const citationWithMapping = citation.endsWith(".txt") ? citation.slice(0, -4) + ".pdf" : citation;
        if (activeCitation === citationWithMapping && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveCitation(citationWithMapping);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
        }

        setSelectedAnswer(index);
    };
    const onSaveContent = (content: AskResponse) => {
        if (selectedOption?.key.toString() === "grant") {
            //let parsedAnswer = useMemo(() => parseAnswerToHtml(content.answer, content.onCitationClicked), [content]);
            const sanitizedAnswerHtml = DOMPurify.sanitize(content.answer); //(parsedAnswer.answerHtml);

            // Convert the content string to a Blob
            const blob = new Blob([sanitizedAnswerHtml], { type: "text/plain;charset=utf-8" });

            // Save the Blob as a txt file using FileSaver.js
            // saveAs(blob, "Response.txt");
            console.log(sanitizedAnswerHtml);
        }
    };

    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            setActiveAnalysisPanelTab(tab);
        }

        setSelectedAnswer(index);
    };

    const placeholderPrompt = placeholderExample[selectedOption?.key.toString() ?? ""];
    return (
        <div className={styles.container}>
            <div className={styles.commandsContainer}>
                <ClearChatButton className={styles.commandButton} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                <SettingsButton className={styles.commandButton} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
            </div>
            <div className={styles.chatRoot}>
                <div className={styles.chatContainer}>
                    {!lastQuestionRef.current ? (
                        <div className={styles.chatEmptyState}>
                            <img src={datasciencedojo_small} alt="Data Science Dojo Logo" className={styles.dataScienceDojoLogo} />
                            <h1 className={styles.chatEmptyStateTitle}>Chat with your data</h1>
                            <div className={styles.subtitleDiv}>
                                <div className={styles.sub1}>
                                    <h2 className={styles.chatEmptyStateSubtitle}>Choose your industry from the dropdown menu:</h2>
                                    <Dropdown options={dropdownOptions} styles={dropdownStyles} selectedKey={selectedOption?.key} onChange={onDropdownChange} />
                                </div>
                            </div>
                            <ExampleList onExampleClicked={onExampleClicked} industry={selectedOption?.key.toString() ?? ""} />
                        </div>
                    ) : (
                        <div className={styles.chatMessageStream}>
                            {answers.map((answer, index) => (
                                <div key={index}>
                                    <UserChatMessage message={answer[0]} />
                                    <div className={styles.chatMessageGpt}>
                                        <Answer
                                            key={index}
                                            answer={answer[1]}
                                            isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                            onCitationClicked={c => onShowCitation(c, index)}
                                            onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                            onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                            onFollowupQuestionClicked={q => makeApiRequest(q)}
                                            showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                            onSaveContentClicked={() => onSaveContent(answer[1])}
                                        />
                                    </div>
                                </div>
                            ))}
                            {isLoading && (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerLoading />
                                    </div>
                                </>
                            )}
                            {error ? (
                                <>
                                    <UserChatMessage message={lastQuestionRef.current} />
                                    <div className={styles.chatMessageGptMinWidth}>
                                        <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current)} />
                                    </div>
                                </>
                            ) : null}
                            <div ref={chatMessageStreamEnd} />
                        </div>
                    )}

                    <div className={styles.chatInput}>
                        <QuestionInput
                            clearOnSend
                            placeholder={placeholderPrompt}
                            //placeholder="Type a new question (e.g. What are data security policies at Contoso Electronics?)"
                            disabled={isLoading}
                            onSend={question => makeApiRequest(question)}
                        />
                    </div>
                </div>

                {answers.length > 0 && activeAnalysisPanelTab && (
                    <AnalysisPanel
                        className={styles.chatAnalysisPanel}
                        activeCitation={activeCitation}
                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                        citationHeight="810px"
                        answer={answers[selectedAnswer][1]}
                        activeTab={activeAnalysisPanelTab}
                    />
                )}

                <Panel
                    headerText="Configure answer generation"
                    isOpen={isConfigPanelOpen}
                    isBlocking={false}
                    onDismiss={() => setIsConfigPanelOpen(false)}
                    closeButtonAriaLabel="Close"
                    onRenderFooterContent={() => <DefaultButton onClick={() => setIsConfigPanelOpen(false)}>Close</DefaultButton>}
                    isFooterAtBottom={true}
                >
                    <TextField
                        className={styles.chatSettingsSeparator}
                        defaultValue={promptTemplate}
                        label="Override prompt template"
                        multiline
                        autoAdjustHeight
                        onChange={onPromptTemplateChange}
                    />

                    <SpinButton
                        className={styles.chatSettingsSeparator}
                        label="Retrieve this many documents from search:"
                        min={1}
                        max={10}
                        defaultValue={retrieveCount.toString()}
                        onChange={onRetrieveCountChange}
                    />
                    <TextField className={styles.chatSettingsSeparator} label="Exclude category" onChange={onExcludeCategoryChanged} />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticRanker}
                        label="Use semantic ranker for retrieval"
                        onChange={onUseSemanticRankerChange}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSemanticCaptions}
                        label="Use query-contextual summaries instead of whole documents"
                        onChange={onUseSemanticCaptionsChange}
                        disabled={!useSemanticRanker}
                    />
                    <Checkbox
                        className={styles.chatSettingsSeparator}
                        checked={useSuggestFollowupQuestions}
                        label="Suggest follow-up questions"
                        onChange={onUseSuggestFollowupQuestionsChange}
                    />
                </Panel>
            </div>
        </div>
    );
};

export default Chat;
